<template>
    <div id="engineering_record" class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata" v-if="!detail.id"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box" v-if="!detail.id">
                <span class="menu_title">保护展示与环境整治工程记录信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <div class="form_basic" :class="isFold ? 'form_basic_hide' : ''">
                <MapDraw
                    :basicMap.sync="taskMap"
                    :drawConf="drawConf"
                    :jsonStr="jsonStr"
                    @update:jsonStr="getJsonStr"
                >
                </MapDraw>
                <Mymap
                    ref="myMap"
                    class="map"
                    :mapData="mapData"
                    :myMap.sync="taskMap"
                >
                    <!-- <div class="map-tool">
                        <sn-button :snButton="newButton" @handleChange="handleChange"></sn-button>
                    </div> -->
                </Mymap>
                <el-form
                    ref="formdata"
                    :inline="false"
                    :model="formdata.saveJson"
                    :rules="rules"
                    class="demo-form-block"
                    size="small"
                    label-width="200px"
                >
                    <sn-button
                        v-if="isFormCheck"
                        class="checkBtn"
                        :snButton="checkButton"
                        @handleChange="handleChange"
                    ></sn-button>
                    <el-form-item label="工程名称" prop="gcmc">
                        <el-input
                            v-model="formdata.saveJson.gcmc"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="遗产组成" prop="sjddyczc">
                        <el-select
                            :disabled="!isEdit"
                            v-model="YSZCBMList"
                            class="ysCheck1"
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            @change="changeYSZCBM"
                        >
                            <el-option
                                v-for="(item, index) in yczcList"
                                :key="index"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <el-checkbox
                            v-model="formdata.saveJson.sjddyczc"
                            :disabled="!isEdit"
                            class="ysCheck2"
                            true-label="无"
                            @change="changeYSZCBM"
                            >无</el-checkbox
                        >
                    </el-form-item>
                    <el-form-item label="遗产要素" prop="sjddycgcys">
                        <el-select
                            :disabled="!isEdit"
                            v-model="YSBMList"
                            class="ysCheck1"
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            @change="changeYSBM"
                        >
                            <el-option
                                v-for="v in ycysList"
                                :key="v.BM"
                                :label="v.MC"
                                :value="v.BM"
                            >
                            </el-option>
                        </el-select>
                        <el-checkbox
                            v-model="formdata.saveJson.sjddycgcys"
                            :disabled="!isEdit"
                            class="ysCheck2"
                            true-label="无"
                            @change="changeYSBM"
                            >无</el-checkbox
                        >
                    </el-form-item>
                    <el-form-item label="工程类型" prop="gcfl">
                        <el-select
                            v-model="formdata.saveJson.gcfl"
                            filterable
                            clearable
                            :disabled="!isEdit"
                        >
                            <el-option
                                v-for="(item, i) in gcflList"
                                :key="i"
                                :label="item"
                                :value="i + ''"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 南京城墙中不维护工程性质 -->
                    <!-- <el-form-item v-if="formdata.saveJson.gcfl === '0'" label="工程性质" prop="gcxz">
                        <el-select v-model="formdata.saveJson.gcxz" filterable clearable :disabled="!isEdit">
                            <el-option v-for="(item, i) in gcxzList" :key="i" :value="i + ''" :label="item"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="文保单位名称">
                        <el-select
                            v-model="formdata.saveJson.wbdwmc"
                            :disabled="!isEdit"
                            filterable
                            clearable
                        >
                            <el-option
                                v-for="v in wbdwList"
                                :key="v.ID"
                                :label="v.DWMC"
                                :value="v.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="施工单位">
                        <el-input
                            v-model="formdata.saveJson.sgdw"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="监理单位" prop="jldw">
                        <el-input
                            v-model="formdata.saveJson.jldw"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="中央财政经费" prop="zyczjf">
                        <el-input
                            v-model="formdata.saveJson.zyczjf"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            @change="specialChanged = true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="省财政经费" prop="shengczjf">
                        <el-input
                            v-model="formdata.saveJson.shengczjf"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            @change="specialChanged = true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="市财政经费" prop="shiczjf">
                        <el-input
                            v-model="formdata.saveJson.shiczjf"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            @change="specialChanged = true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="市以下财政经费" prop="syxczjf">
                        <el-input
                            v-model="formdata.saveJson.syxczjf"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            @change="specialChanged = true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="自筹经费" prop="zcjf">
                        <el-input
                            v-model="formdata.saveJson.zcjf"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            @change="specialChanged = true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="保护工程经费-国家补助">
                        <el-tooltip
                            effect="light"
                            content="默认为中央财政经费总额，可修改。"
                            placement="bottom"
                        >
                            <el-input
                                v-model="formdata.saveJson.bhgcgjbzjf"
                                onkeydown="if(event.keyCode==32) return false"
                                :disabled="!isEdit"
                            >
                                <template slot="append">万元</template>
                            </el-input>
                        </el-tooltip>
                    </el-form-item>
                    <el-form-item label="保护工程经费-地方配套">
                        <el-tooltip
                            effect="light"
                            content="默认为省财政经费、市财政经费及市以下财政经费总额，可修改。"
                            placement="bottom"
                        >
                            <el-input
                                v-model="formdata.saveJson.bhgcdfptjf"
                                onkeydown="if(event.keyCode==32) return false"
                                :disabled="!isEdit"
                            >
                                <template slot="append">万元</template>
                            </el-input>
                        </el-tooltip>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="投入经费总额" prop="gjtrzjf">
                        <el-tooltip
                            effect="light"
                            content="默认为中央财政经费、省财政经费、市财政经费、市以下财政经费及自筹经费总额，可修改。"
                            placement="bottom"
                        >
                            <el-input
                                v-model="formdata.saveJson.gjtrzjf"
                                :disabled="!specialIsEdit"
                                onkeydown="if(event.keyCode==32) return false"
                                @change="specialChanged = true"
                            >
                                <template slot="append">万元</template>
                            </el-input>
                        </el-tooltip>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="开工时间" prop="kgsj">
                        <el-date-picker
                            v-model="formdata.saveJson.kgsj"
                            :picker-options="pickerKGSJOptions"
                            value-format="yyyy-MM-dd"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            type="date"
                            @change="specialChanged = true"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="竣工时间" prop="jgsj">
                        <el-date-picker
                            v-model="formdata.saveJson.jgsj"
                            :picker-options="pickerJGSJOptions"
                            value-format="yyyy-MM-dd"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            type="date"
                            @change="specialChanged = true"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="竣工验收日期">
                        <el-date-picker
                            v-model="formdata.saveJson.jgysrq"
                            :picker-options="pickerJGYSRQOptions"
                            value-format="yyyy-MM-dd"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="!isEdit"
                            type="date"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="保护工程进展情况">
                        <!-- <el-input v-model="formdata.saveJson.bhgcjzqkmc" readonly></el-input> -->
                        <el-select
                            v-model="formdata.saveJson.bhgcjzqk"
                            readonly
                            disabled
                        >
                            <el-option
                                v-for="(item, index) in bhgcjzqkList"
                                :key="index"
                                :label="item"
                                :value="index"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div
                        class=""
                        :class="[
                            detail.id
                                ? 'tableAtMapBottomJcy'
                                : 'tableAtMapBottom',
                        ]"
                    >
                        <el-form-item label="异常事件">
                            <el-select
                                v-model="formdata.saveJson.ycjlid"
                                clearable
                                filterable
                                :disabled="!isEdit"
                                @change="getErrorDetail()"
                            >
                                <el-option
                                    v-for="(item, index) in errorList"
                                    :key="index"
                                    :label="item.YCSJ"
                                    :value="item.ID"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <!-- 异常事件详情部分 -->
            <div class="errorPartWrap flex" v-if="errorPart.dialog && !isFold">
                <div class="errorDesc">
                    <div class="errorItem flex pointer" :title="errorPart.qkms">
                        <div>情况描述：</div>
                        <div class="ellipsis1 flex-1">{{ errorPart.qkms }}</div>
                    </div>
                    <div class="errorItem flex">
                        <div>遗产要素：</div>
                        <div>{{ errorPart.ycys }}</div>
                    </div>
                    <div
                        class="errorItem flex pointer"
                        :title="errorPart.position"
                    >
                        <div>位置：</div>
                        <div class="ellipsis1 flex-1">
                            {{ errorPart.position }}
                        </div>
                    </div>
                    <div class="errorItem flex">
                        <div>时间：</div>
                        <div>{{ errorPart.time }}</div>
                    </div>
                    <div class="errorItem flex">
                        <div>评估：</div>
                        <div v-if="errorPart.assess">
                            {{ errorPart.assess }}
                        </div>
                    </div>
                </div>
                <div class="carouselPart flex-1 carouselArrowShow">
                    <el-carousel
                        indicator-position="none"
                        height="167px"
                        :autoplay="false"
                        :arrow="
                            errorPart.imgList.length > 1 ? 'hover' : 'never'
                        "
                        class="imgPart"
                    >
                        <el-carousel-item
                            v-for="(item, index) in errorPart.imgList"
                            :key="index"
                            class="pointer"
                            v-viewer
                        >
                            <div class="flex imgItem">
                                <el-image
                                    v-for="(item1, index1) in item"
                                    :key="index1"
                                    :src="item1.SYT"
                                    fit="cover"
                                    class="pointer"
                                    style="
                                        width: 24%;
                                        height: 100%;
                                        border-radius: 4px;
                                        margin-right: 10px;
                                    "
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData width="150px"></SnNoData>
                                    </div>
                                </el-image>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="fold">
                <i
                    :class="
                        isFold ? 'el-icon-caret-bottom' : 'el-icon-caret-top'
                    "
                    :title="
                        isFold
                            ? '展开保护展示与环境整治工程记录信息'
                            : '折叠保护展示与环境整治工程记录信息'
                    "
                    @click="isFold = !isFold"
                ></i>
            </div>
            <div class="botton_box">
                <span class="menu_title">文物保护维修档案</span>
            </div>
            <div class="form_info">
                <el-tabs class="step-tabs" type="card">
                    <el-tab-pane>
                        <span slot="label" class="tab-name"
                            >立项/方案<i :class="setIconClass('lxfa')"></i
                        ></span>
                        <div class="tab-main">
                            <el-form
                                ref="formdata1"
                                :inline="true"
                                :model="formdata.saveJson"
                                :rules="rules"
                                size="small"
                                class="demo-form-inline form_row"
                                label-width="200px"
                            >
                                <p class="model-title">立项基本信息</p>
                                <el-form-item label="立项名称">
                                    <el-input
                                        v-model="formdata.saveJson.famc"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input> </el-form-item
                                ><el-form-item label="批准复文号">
                                    <el-input
                                        v-model="formdata.saveJson.pzfwh"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    label="立项开始年月"
                                    prop="lxksny"
                                >
                                    <el-date-picker
                                        v-model="formdata.saveJson.lxksny"
                                        format="yyyy-MM"
                                        value-format="yyyy-MM"
                                        type="month"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-date-picker>
                                </el-form-item>

                                <p class="model-title">方案基本信息</p>
                                <el-form-item label="设计单位" prop="sjdw">
                                    <el-input
                                        v-model="formdata.saveJson.sjdw"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="方案开始年月">
                                    <el-date-picker
                                        v-model="formdata.saveJson.faksny"
                                        format="yyyy-MM"
                                        value-format="yyyy-MM"
                                        type="month"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item
                                    label="方案设计阶段"
                                    prop="fasjjd"
                                >
                                    <el-input
                                        v-model="formdata.saveJson.fasjjd"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="方案编制单位">
                                    <el-input
                                        v-model="formdata.saveJson.fabzdw"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="方案批准复文号">
                                    <el-input
                                        v-model="formdata.saveJson.fapfwh"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    label="方案批复年份"
                                    prop="fapfnf"
                                >
                                    <el-date-picker
                                        v-model="formdata.saveJson.fapfnf"
                                        type="year"
                                        format="yyyy"
                                        value-format="yyyy"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <!-- 任何时间可编辑 -->
                                <el-form-item label="方案设计经费-国家补助">
                                    <el-input
                                        v-model="formdata.saveJson.fasjgjbzjf"
                                        :disabled="!specialIsEdit"
                                        onkeydown="if(eventkey.Code==32) return false"
                                        @change="specialChanged = true"
                                    >
                                        <template slot="append">万元</template>
                                    </el-input>
                                </el-form-item>
                                <!-- 任何时间可编辑 -->
                                <el-form-item label="方案设计经费-地方配套">
                                    <el-input
                                        v-model="formdata.saveJson.fasjdfptjf"
                                        :disabled="!specialIsEdit"
                                        onkeydown="if(event.keyCode==32) return false"
                                        @change="specialChanged = true"
                                    >
                                        <template slot="append">万元</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="是否上报世界遗产中心">
                                    <el-select
                                        v-model="formdata.saveJson.sfsbsjyczx"
                                        filterable
                                        clearable
                                        :disabled="!isEdit"
                                    >
                                        <el-option
                                            value="1"
                                            label="是"
                                        ></el-option>
                                        <el-option
                                            value="0"
                                            label="否"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>

                                <p class="model-title">立项/方案 附件</p>
                                <div class="file-main">
                                    <div v-for="(item, i) in lxfaList" :key="i">
                                        <p
                                            v-if="i === lxfaList.length - 1"
                                            class="file-title"
                                        >
                                            是否有其他类型的附件
                                            <el-radio-group
                                                v-model="
                                                    formdata.saveJson.lxfaqtfile
                                                "
                                                :disabled="
                                                    !specialIsEdit ||
                                                    (formdata.saveJson.shzt ===
                                                        2 &&
                                                        lxfaqtfile === 1)
                                                "
                                            >
                                                <el-radio :label="1"
                                                    >是</el-radio
                                                >
                                                <el-radio :label="0"
                                                    >否</el-radio
                                                >
                                            </el-radio-group>
                                        </p>
                                        <div
                                            v-if="
                                                i !== lxfaList.length - 1 ||
                                                formdata.saveJson.lxfaqtfile
                                            "
                                        >
                                            <p class="file-title">
                                                {{ item.fjlb
                                                }}<i
                                                    :class="
                                                        checkFileState(
                                                            item.metaJson
                                                        )
                                                    "
                                                ></i>
                                            </p>
                                            <basic-upload
                                                ref="uploadFile"
                                                :isCheckShow="isCheck"
                                                :code="item.code"
                                                :isEdit="specialIsEdit"
                                                :itemId="itemId"
                                                :metaData.sync="item.metaJson"
                                                basicType="document"
                                                @handleChange="handleChangeFile"
                                            ></basic-upload>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-name"
                            >过程档案<i :class="setIconClass('gcda')"></i
                        ></span>
                        <div class="tab-main">
                            <el-form
                                ref="formdata2"
                                :inline="true"
                                :model="formdata.saveJson"
                                :rules="rules"
                                size="small"
                                class="demo-form-inline form_row"
                                label-width="200px"
                            >
                                <div class="file-main">
                                    <div v-for="(item, i) in gcdaList" :key="i">
                                        <p
                                            v-if="i === gcdaList.length - 1"
                                            class="file-title"
                                        >
                                            是否有其他类型的附件
                                            <el-radio-group
                                                v-model="
                                                    formdata.saveJson.gcdaqtfile
                                                "
                                                :disabled="
                                                    !specialIsEdit ||
                                                    (formdata.saveJson.shzt ===
                                                        2 &&
                                                        gcdaqtfile === 1)
                                                "
                                            >
                                                <el-radio :label="1"
                                                    >是</el-radio
                                                >
                                                <el-radio :label="0"
                                                    >否</el-radio
                                                >
                                            </el-radio-group>
                                        </p>
                                        <div
                                            v-if="
                                                i !== gcdaList.length - 1 ||
                                                formdata.saveJson.gcdaqtfile
                                            "
                                        >
                                            <p class="file-title">
                                                {{ item.fjlb
                                                }}<i
                                                    :class="
                                                        checkFileState(
                                                            item.metaJson
                                                        )
                                                    "
                                                ></i>
                                            </p>
                                            <basic-upload
                                                ref="uploadFile"
                                                :isCheckShow="isCheck"
                                                :code="item.code"
                                                :isEdit="specialIsEdit"
                                                :itemId="itemId"
                                                :metaData.sync="item.metaJson"
                                                basicType="document"
                                                @handleChange="handleChangeFile"
                                            ></basic-upload>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-name"
                            >竣工验收<i :class="setIconClass('jgys')"></i
                        ></span>
                        <div class="tab-main">
                            <el-form
                                ref="formdata3"
                                :inline="true"
                                :model="formdata.saveJson"
                                :rules="rules"
                                size="small"
                                class="demo-form-inline form_row"
                                label-width="200px"
                            >
                                <el-form-item label="竣工验收日期">
                                    <el-date-picker
                                        v-model="formdata.saveJson.jgysrq"
                                        value-format="yyyy-MM-dd"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                        type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item
                                    label="竣工验收文号"
                                    prop="jgyswh"
                                >
                                    <el-input
                                        v-model="formdata.saveJson.jgyswh"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="国家文物局验收日期">
                                    <el-date-picker
                                        v-model="formdata.saveJson.gjwwjysrq"
                                        value-format="yyyy-MM-dd"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                        type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="工程验收结论">
                                    <el-input
                                        v-model="formdata.saveJson.jgysjl"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="!isEdit"
                                    >
                                    </el-input>
                                </el-form-item>
                                <div class="file-main">
                                    <div v-for="(item, i) in jgysList" :key="i">
                                        <p
                                            v-if="i === jgysList.length - 1"
                                            class="file-title"
                                        >
                                            是否有其他类型的附件
                                            <el-radio-group
                                                v-model="
                                                    formdata.saveJson.jgysqtfile
                                                "
                                                :disabled="
                                                    !specialIsEdit ||
                                                    (formdata.saveJson.shzt ===
                                                        2 &&
                                                        jgysqtfile === 1)
                                                "
                                            >
                                                <el-radio :label="1"
                                                    >是</el-radio
                                                >
                                                <el-radio :label="0"
                                                    >否</el-radio
                                                >
                                            </el-radio-group>
                                        </p>
                                        <div
                                            v-if="
                                                i !== jgysList.length - 1 ||
                                                formdata.saveJson.jgysqtfile
                                            "
                                        >
                                            <p class="file-title">
                                                {{ item.fjlb
                                                }}<i
                                                    :class="
                                                        checkFileState(
                                                            item.metaJson
                                                        )
                                                    "
                                                ></i>
                                            </p>
                                            <basic-upload
                                                ref="uploadFile"
                                                :isCheckShow="isCheck"
                                                :code="item.code"
                                                :isEdit="specialIsEdit"
                                                :itemId="itemId"
                                                :metaData.sync="item.metaJson"
                                                basicType="document"
                                                @handleChange="handleChangeFile"
                                            ></basic-upload>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
import MapDraw from "@comp/map/mapDraw/MapDraw";
export default {
    name: "engineering_record",
    components: {
        MapDraw,
    },
    mixins: [infoMixin],
    props: {
        detail: {
            type: Object,
            default: {
                dialog: false,
                type: 2,
                id: "",
                version: "",
                fid: "",
                ItemID: "24",
                basicType: "document",
                ytID: "RWID",
            },
        },
    },
    data() {
        let checkNum = (rule, value, callback) => {
            if (value === "") {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        return {
            mapData: {
                dxtState: true,
                areaState: true,
                baseMapNum: 1,
                mapTypeBtn: false,
            },
            yczcList: [], // 遗产组成列表
            yczcValueList: [], // 遗产组成列表
            snButton: snButton,
            checkButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveBasePass",
                        editType: "2",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveBaseNoPass",
                        editType: "2",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            taskMap: null,
            isFold: false,
            specialIsEdit: false, // 控制特殊编辑项--有编辑权限的随时可编辑
            specialChanged: false,
            isCheck: false, // 附件是否可审核
            isFormCheck: false, // 表单是否可审核
            YSBMList: [], // 涉及的遗产要素（多选）
            YSZCBMList: [], //遗产组成
            sjdycys: "", // 可选无涉及的遗产要素
            ycysList: [], // 涉及的遗产要素列表
            wbdwList: [], // 文保单位列表
            gcflList: [
                "本体保护工程",
                "展示工程",
                "保护性设施建设工程（安消防除外）",
                "环境整治工程",
                "监测工程",
                "其他",
            ],
            gcxzList: [
                "保养维护工程",
                "抢险加固工程",
                "修缮工程",
                "保护性设施建设工程",
                "迁移工程",
            ],
            // 开工时间
            pickerKGSJOptions: {
                disabledDate: (time) => {
                    return (
                        (this.formdata.saveJson.jgsj &&
                            time.getTime() >
                                new Date(
                                    this.formdata.saveJson.jgsj
                                ).getTime()) ||
                        (this.formdata.saveJson.jgysrq &&
                            time.getTime() >
                                new Date(
                                    this.formdata.saveJson.jgysrq
                                ).getTime())
                    );
                },
            },
            // 竣工时间
            pickerJGSJOptions: {
                disabledDate: (time) => {
                    return (
                        time.getTime() <
                            new Date(this.formdata.saveJson.kgsj).getTime() ||
                        (this.formdata.saveJson.jgysrq &&
                            time.getTime() >
                                new Date(
                                    this.formdata.saveJson.jgysrq
                                ).getTime()) ||
                        time.getTime() > new Date().getTime()
                    );
                },
            },
            // 竣工验收时间
            pickerJGYSRQOptions: {
                disabledDate: (time) => {
                    return (
                        time.getTime() <
                            new Date(this.formdata.saveJson.kgsj).getTime() ||
                        time.getTime() <
                            new Date(this.formdata.saveJson.jgsj).getTime() ||
                        time.getTime() > new Date().getTime()
                    );
                },
            },
            // planB
            newButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "creatArea",
                        name: "绘制工程范围",
                        round: true,
                        backColor: "#28ccd9",
                        color: "#fff",
                    },
                ],
            },
            // 立项方案附件
            lxfaList: [
                { code: "8", metaJson: [], fjlb: "立项批复文件" },
                { code: "9", metaJson: [], fjlb: "立项报告" },
                { code: "10", metaJson: [], fjlb: "方案" },
                { code: "11", metaJson: [], fjlb: "方案批复文件" },
                { code: "12", metaJson: [], fjlb: "不同意及其他文件" },
                { code: "0", metaJson: [], fjlb: "其他" },
            ],
            // 过程档案附件
            gcdaList: [
                { code: "13", metaJson: [], fjlb: "监理报告" },
                { code: "1", metaJson: [], fjlb: "其他" },
            ],
            // 竣工验收附件
            jgysList: [
                { code: "14", metaJson: [], fjlb: "竣工报告" },
                { code: "16", metaJson: [], fjlb: "专家意见" },
                { code: "2", metaJson: [], fjlb: "其他" },
            ],
            rules: {
                gcmc: [
                    {
                        required: true,
                        message: "请填写工程名称",
                        trigger: "blur",
                    },
                ],
                gcfl: [
                    {
                        required: true,
                        message: "请选择工程类型",
                        trigger: "change",
                    },
                ],
                sjddyczc: [
                    {
                        required: true,
                        message: "请选择涉及到的遗产组成",
                        trigger: "change",
                    },
                ],
                sjddycgcys: [
                    {
                        required: true,
                        message: "请选择涉及到的遗产构成要素",
                        trigger: "change",
                    },
                ],
                jldw: [
                    {
                        required: true,
                        message: "请填写监理单位",
                        trigger: "blur",
                    },
                ],
                shengczjf: [{ validator: checkNum, trigger: "change" }],
                shiczjf: [{ validator: checkNum, trigger: "change" }],
                syxczjf: [{ validator: checkNum, trigger: "change" }],
                zcjf: [{ validator: checkNum, trigger: "change" }],
                zyczjf: [{ validator: checkNum, trigger: "change" }],
                gjtrzjf: [
                    {
                        required: true,
                        message: "请填写投入经费总额",
                        trigger: "blur",
                    },
                    { validator: checkNum, trigger: "change" },
                ],
                kgsj: [
                    {
                        required: true,
                        message: "请选择开工时间",
                        trigger: "blur",
                    },
                ],
                lxksny: [
                    {
                        required: true,
                        message: "请选择立项开始年月",
                        trigger: "blur",
                    },
                ],
                jgyswh: [
                    {
                        required: true,
                        message: "请填写竣工验收文号",
                        trigger: "blur",
                    },
                ],
                sjdw: [
                    {
                        required: true,
                        message: "请填写设计单位",
                        trigger: "blur",
                    },
                ],
                fasjjd: [
                    {
                        required: true,
                        message: "请填写方案设计阶段",
                        trigger: "blur",
                    },
                ],
                fapfnf: [
                    {
                        required: true,
                        message: "请选择方案批复年份",
                        trigger: "blur",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    ycjlid: "", //异常事件id
                    gcmc: "",
                    sjddyczc: "",
                    sjddycgcys: "",
                    gcfl: "",
                    gcxz: "",
                    wbdwmc: "",
                    sgdw: "",
                    jldw: "",
                    zyczjf: "",
                    shengczjf: "",
                    shiczjf: "",
                    syxczjf: "",
                    zcjf: "",
                    gjtrzjf: "",
                    bhgcgjbzjf: "",
                    bhgcdfptjf: "",
                    kgsj: "",
                    jgsj: "",
                    jgysrq: "",
                    famc: "",
                    pzfwh: "",
                    lxksny: "",
                    sjdw: "",
                    faksny: "",
                    fasjjd: "",
                    fabzdw: "",
                    fapfwh: "",
                    fapfnf: null,
                    fasjgjbzjf: "",
                    fasjdfptjf: "",
                    sfsbsjyczx: "",
                    jgyswh: "",
                    gjwwjysrq: "",
                    jgysjl: "",
                    gcxmzb: "", // 工程范围
                    lxfaqtfile: 0, // 立项/方案是否有其他类型的附件
                    gcdaqtfile: 0, // 过程档案是否有其他类型的附件
                    jgysqtfile: 0, // 竣工验收是否有其他类型的附件
                    glycbtid: this.heritageId, // 写死就好
                    bhgcjzqk: "", // 保护工程进展情况枚举
                    shzt: 1,
                },
                metaJson: [],
                heritageId: this.heritageId,
                fileID: "",
                originalFileID: "",
                itemID: "24",
                userName: "",
                userId: "",
            },
            bhgcjzqkList: ["未开工", "施工中", "竣工待验收", "已验收"],
            lxfaqtfile: 0, // 立项/方案是否有其他类型的附件
            gcdaqtfile: 0, // 过程档案是否有其他类型的附件
            jgysqtfile: 0, // 竣工验收是否有其他类型的附件
            jsonStr: null,
            drawConf: {
                position: "topleft",
                show: true,
                draw: {
                    marker: false,
                    polygon: true,
                    polyline: false,
                    rectangle: true,
                },
            },
            errorList: [], //异常事件列表
            errorPart: {
                dialog: false,
                qkms: "",
                ycys: "",
                position: "",
                time: "",
                assess: "",
                imgList: "",
            },
        };
    },
    computed: {
        jzqk() {
            let data = this.formdata.saveJson;
            let res = [data.kgsj, data.jgsj, data.jgysrq];
            return res;
        },
        gjbzChange() {
            let res = this.formdata.saveJson.zyczjf;
            return res;
        },
        dfptChange() {
            let data = this.formdata.saveJson;
            let res = [data.shengczjf, data.shiczjf, data.syxczjf];
            return res;
        },
        jfzeChange() {
            let data = this.formdata.saveJson;
            let res = [
                data.zyczjf,
                data.shengczjf,
                data.shiczjf,
                data.syxczjf,
                data.zcjf,
            ];
            return res;
        },
    },
    watch: {
        jzqk(val) {
            let [kgsj, jgsj, jgysrq] = val;
            let now = this.common.getTimeYmd();
            if (jgysrq && jgysrq <= now) {
                this.formdata.saveJson.bhgcjzqk = 3; // '已验收'
            } else if (jgsj && jgsj <= now) {
                this.formdata.saveJson.bhgcjzqk = 2; // '竣工待验收'
            } else if (kgsj && kgsj <= now) {
                this.formdata.saveJson.bhgcjzqk = 1; // '施工中'
            } else if (!kgsj || (kgsj && kgsj > now)) {
                this.formdata.saveJson.bhgcjzqk = 0; // '未开工'
            } else {
                this.formdata.saveJson.bhgcjzqk = "";
            }
        },
        gjbzChange(val) {
            this.formdata.saveJson.bhgcgjbzjf = Number(val);
        },
        dfptChange(val) {
            this.formdata.saveJson.bhgcdfptjf = 0;
            val.forEach((item) => {
                this.formdata.saveJson.bhgcdfptjf += Number(item);
            });
        },
        jfzeChange(val) {
            this.formdata.saveJson.gjtrzjf = 0;
            val.forEach((item) => {
                this.formdata.saveJson.gjtrzjf += Number(item);
            });
        },
    },
    created() {
        this.getYczcList();
        this.getYcys();
        this.getWBDWList();
        this.getErrorList();
    },
    mounted() {},
    methods: {
        ...mapActions([
            "getYcysList",
            "GetYcysListByYczc",
            "UpdateJCSJBhgcFilesSHZT",
            "GetGLXList",
            "GetYczcListByYcys",
            "GetAllYcjl",
            "GetRcxcycjlDetail",
        ]),
        getDataNext() {
            // detail 监测云 异常预警部分 嵌入的props
            if (this.$route.query.id || this.detail.id) {
                let obj = this.common.aryListFindOne(
                    this.wbdwList,
                    "ID",
                    this.formdata.saveJson.wbdwmc
                );
                // 单位名称删除
                if (!obj.ID) {
                    this.formdata.saveJson.wbdwmc = "";
                }
                if (this.formdata.saveJson.sjddyczc) {
                    this.YSZCBMList =
                        this.formdata.saveJson.sjddyczc.split(",");
                }
                if (this.formdata.saveJson.sjddycgcys) {
                    this.YSBMList =
                        this.formdata.saveJson.sjddycgcys.split(",");
                }
                this.getErrorDetail();
            }
        },
        // 获取异常记录列表
        async getErrorList() {
            let result = await this.GetAllYcjl({
                qyid: "",
                sfjc: "0,1", //0 未解除 1 已解除  2误报
            });
            this.errorList = result;
        },
        // 获取异常记录 详情
        async getErrorDetail() {
            let result = await this.GetRcxcycjlDetail({
                ycjlid: this.formdata.saveJson.ycjlid,
            });
            this.errorPart.dialog = true;
            if (result.length) {
                this.errorPart.qkms = result[0].SJMS;
                this.errorPart.ycys = result[0].MC ? result[0].MC : "无";
                this.errorPart.position = result[0].YCFSWZ;
                this.errorPart.time = result[0].CJSJ;
                this.errorPart.assess = result[0].PG_DESC;
                result[0].PIC = [...result[0].PIC, ...result[0].PIC];
                if (result[0].PIC.length) {
                    this.errorPart.imgList = this.common.arrayDevide(
                        result[0].PIC,
                        4
                    );
                } else {
                    this.errorPart.imgList = [];
                }
            } else {
                this.errorPart.qkms = "";
                this.errorPart.ycys = "";
                this.errorPart.position = "";
                this.errorPart.time = "";
                this.errorPart.assess = "";
                this.errorPart.imgList = [];
            }
        },
        // 获取遗产组成列表
        async getYczcList() {
            let result = await this.GetYczcListByYcys({
                ycysbm: this.formdata.saveJson.sjddycgcys,
            });
            let list = [];
            result.RESULTVALUE.map((item) => {
                list.push(item.YCYSFL_2);
            });
            this.yczcValueList = Array.from(new Set(list));
            list.unshift("全部选择");
            this.yczcList = Array.from(new Set(list));
        },
        async getYcys() {
            // let res = await this.getYcysList({
            //     ycdbm: this.heritageId,
            // });
            // let list = [
            //     {
            //         Bm: "all",
            //         Mc: "全部选择",
            //         BmList: [],
            //     },
            // ];
            // res.ResultValue.map((item) => {
            //     list[0].BmList.push(item.Bm);
            // });
            // this.ycysList = [...list, ...res.ResultValue];
            let res = await this.GetYcysListByYczc({
                yczc: this.formdata.saveJson.sjddycgcys,
            });
            let list = [
                {
                    BM: "all",
                    MC: "全部选择",
                    BmList: [],
                },
            ];
            res.RESULTVALUE.map((item) => {
                list[0].BmList.push(item.BM);
            });
            this.ycysList = [...list, ...res.RESULTVALUE];
        },
        changeYSZCBM(val) {
            if (typeof val === "object") {
                if (val.includes("全部选择")) {
                    this.formdata.saveJson.sjddyczc =
                        this.yczcValueList.join(",");
                    this.YSZCBMList = this.yczcValueList;
                } else {
                    this.formdata.saveJson.sjddyczc = val.join(",");
                }
            } else {
                this.formdata.saveJson.sjddyczc = val === "无" ? "无" : "";
                this.YSZCBMList = [];
            }
            if (this.formdata.saveJson.sjddyczc == "无") {
                this.formdata.saveJson.sjddycgcys = "无";
                this.YSBMList = [];
                this.ycysList = [];
            } else {
                this.formdata.saveJson.sjddycgcys = "";
                this.YSBMList = [];
                this.ycysList = [];
                this.getYcys();
            }
        },
        changeYSBM(val) {
            if (this.formdata.saveJson.sjddyczc == "") {
                this.common.showMsg("请先选中遗产组成", "warning");
                this.formdata.saveJson.sjddycgcys = "";
                this.YSBMList = [];
                return;
            }
            if (typeof val === "object") {
                if (val.includes("all")) {
                    this.formdata.saveJson.sjddycgcys =
                        this.ycysList[0].BmList.join(",");
                    this.YSBMList = this.ycysList[0].BmList;
                } else {
                    this.formdata.saveJson.sjddycgcys = val.join(",");
                }
            } else {
                this.formdata.saveJson.sjddycgcys = val === "无" ? "无" : "";
                this.YSBMList = [];
            }
        },
        setMetaJsonData(shzt) {
            let fileList = ["lxfaList", "gcdaList", "jgysList"];
            this.formdata.metaJson = [];
            fileList.forEach((item) => {
                this[item].forEach((ele) => {
                    ele.metaJson.forEach((item) => {
                        item.shzt =
                            item.shzt === 1 || item.shzt === 3
                                ? shzt
                                : item.shzt;
                    });
                    this.formdata.metaJson = this.formdata.metaJson.concat(
                        ele.metaJson
                    );
                });
            });
        },
        getMetaJsonData() {
            let allFile = this.formdata.metaJson;
            let fileList = ["lxfaList", "gcdaList", "jgysList"];
            fileList.forEach((item) => {
                this[item].forEach((ele) => {
                    ele.metaJson = allFile.filter((i) => {
                        return i.lx === ele.code;
                    });
                });
            });
            this.lxfaqtfile = this.formdata.saveJson.lxfaqtfile;
            this.gcdaqtfile = this.formdata.saveJson.gcdaqtfile;
            this.jgysqtfile = this.formdata.saveJson.jgysqtfile;
        },
        // 新建巡查范围
        creatArea() {
            // let option = {
            //     allowIntersection: false,
            //     showArea: true
            // };
            // this.isFirstinPolygon = false;
            // // eslint-disable-next-line
            // this.myPolygon = new L.Draw.Polygon(this.taskMap, option);
            // this.myPolygon.enable();
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            let jsonStr = JSON.parse(JsonStr);
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            if (jsonStr.length === 0) return false;
            let json = jsonStr[jsonStr.length - 1].geometry;
            if (json.type !== "Polygon") return false;
            this.pointJson = jsonStr;
            let typeList = ["draw:created", "draw:editstop", "draw:deleted"]; // 创建、编辑、删除
            if (typeList.indexOf(event.type) > -1) {
                this.formdata.saveJson.gcxmzb = JsonStr;
            }
        },
        getArea() {
            let areaJson = this.formdata.saveJson.gcxmzb;
            if (!areaJson) return false;
            this.jsonStr = areaJson; // 后续在mapDraw中渲染区域
        },
        // 控制按钮显示
        contralBtn() {
            // type: 0 => 新建; 1 => 编辑; 2 => 查看 3=> 审核
            // btnType: 0 查看  1 编辑  2 审核
            let shzt = this.formdata.saveJson.shzt;
            let btnType =
                this.type === "0" || this.type === "1"
                    ? "1"
                    : this.type === "3"
                    ? "2"
                    : "0";
            if ((shzt === 2 || shzt === 0) && btnType === "1") {
                // 审核通过后只可以修改特殊项
                this.btnType = 0;
            } else {
                this.btnType = btnType;
            }
            this.isEdit = this.btnType === "1";
            this.specialIsEdit =
                this.commonMethods.isPower("1") && btnType === "1"; // 当前登录人是否有编辑的权限，控制随时可编辑的那几项
            this.isCheck = this.commonMethods.isPower("2") && btnType === "2"; // 当前登录人是否有审核的权限
            this.isFormCheck =
                this.commonMethods.isPower("2") &&
                btnType === "2" &&
                shzt !== 2 &&
                shzt !== 3; // 当前登录人是否有审核的权限
            this.snButton.buttonData.forEach((item, index) => {
                if (item.name == "保存") {
                    if (
                        this.type === "999" &&
                        (this.formdata.saveJson.shzt == 0 ||
                            this.formdata.saveJson.shzt == 2) &&
                        localStorage.roleId
                            .split(",")
                            .includes(window.ROLE_ID.CJGLY)
                    ) {
                        this.isEdit = true; //输入框可以编辑
                        item.isShow = true; //详情页面显示 保存按钮
                        this.specialIsEdit = true;
                    } else {
                        item.isShow = false;
                    }
                } else {
                    if (item.name == "审核通过" || item.name == "审核不通过") {
                        item.isShow = false;
                    } else {
                        item.isShow =
                            item.editType.split(",").indexOf(btnType) > -1;
                    }
                }
            });
            this.drawConf.show = this.isEdit;

            if (this.detail.id) {
                this.isEdit = false;
            }
        },
        // 检查每一个文件后面的图标状态
        checkFileState(data) {
            let className = "";
            let hasNoPass = false;
            if (this.isEdit) {
                hasNoPass = data.some((item) => {
                    return item.shzt === 3;
                });
                className =
                    data.length === 0 || hasNoPass
                        ? "el-icon-warning"
                        : "el-icon-success";
            } else if (this.isCheck) {
                hasNoPass = data.some((item) => {
                    return !item.shzt;
                });
                className = hasNoPass ? "el-icon-warning" : "el-icon-success";
            }
            return className;
        },
        // 图标控制
        setIconClass(name) {
            let res = this.checkAllFileState(this[name + "List"]);
            return res;
        },
        // 检查文件的状态
        checkAllFileState(data) {
            // 编辑：未上传、审核未通过 -- warning
            // 审核：未审核 -- warning
            // 查看：不显示状态
            if (this.btnType === "0") {
                // 查看
                return "";
            } else if (this.btnType === "1") {
                // 编辑
                let res = data.every((item) => {
                    let res1 = item.metaJson.every((ele) => {
                        return ele.shzt === "2";
                    });
                    return res1 && item.metaJson.length > 0;
                });
                return res ? "el-icon-success" : "el-icon-warning";
            } else if (this.btnType === "2") {
                // 审核
                let res = data.every((item) => {
                    let res1 = item.metaJson.every((ele) => {
                        return ele.shzt;
                    });
                    return res1;
                });
                return res ? "el-icon-success" : "el-icon-warning";
            }
        },
        // 文件审核
        handleChangeFile(type, value) {
            if (type == "savePass") {
                this.savePassInner(value);
            } else {
                this.saveNoPassInner(value);
            }
            // this[type](value);
        },
        // 审核通过
        savePassInner(value) {
            let id = typeof value === "string" ? value : "";
            this.saveSHZTInner(2, id);
        },
        // 审核不通过
        saveNoPassInner(value) {
            let id = typeof value === "string" ? value : "";
            this.saveSHZTInner(3, id);
        },
        saveSHZTInner(zt, id) {
            // id为保护展示与环境整治工程记录信息中审核每一个附件的id，id不存在即为审核整条的基础数据
            this.$confirm("审核提交后不能更改，你确认要提交吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 获取未审核通过的原因
                    if (Number(zt) === 3) {
                        this.$prompt("请输入未审核通过原因", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                        })
                            .then(({ value }) => {
                                if (value == null || value.trim().length == 0) {
                                    this.common.showMsg(
                                        "输入无效,请重新审核",
                                        "info"
                                    );
                                } else {
                                    this.updateSjztInner(zt, id, value);
                                }
                            })
                            .catch(() => {
                                this.common.showMsg("已取消", "info");
                            });
                    } else {
                        // 审核通过
                        this.updateSjztInner(zt, id);
                    }
                })
                .catch(() => {
                    this.common.showMsg("已取消审核", "info");
                });
        },
        // 审核状态改变
        async updateSjztInner(zt, id, reason) {
            let sjid = "";
            if (id) {
                sjid = id;
            } else if (this.formdata.saveJson && this.formdata.saveJson.id) {
                sjid = this.formdata.saveJson.id;
            } else if (
                this.formdata.saveJson.wbdwjbxx &&
                this.formdata.saveJson.wbdwjbxx.id
            ) {
                sjid = this.formdata.saveJson.wbdwjbxx.id;
            } else if (
                this.formdata.saveJson.mainlist &&
                this.formdata.saveJson.mainlist[0].id
            ) {
                sjid = this.formdata.saveJson.mainlist[0].id;
            }
            if (!sjid) {
                this.common.showMsg("审核失败", "error");
                return;
            }
            let itemID =
                this.ItemID === "28" ? this.$route.query.ytID : this.ItemID;
            // 保护展示与环境整治工程 24   文件上传
            let url = "UpdateJCSJBhgcFilesSHZT";
            let res = await this[url]({
                funId: itemID,
                sjid: sjid,
                shzt: zt,
                shrid: localStorage.userId,
                shsj: this.common.getTimeYmdhms(),
                shbtgsm: reason ? reason : "",
                rwid: this.$route.query.RWID,
            });
            if (res.IsSuccess) {
                this.common.showMsg("审核成功", "success");
                if (!id) {
                    // 审核某一个附件不用返回列表
                    this.returnList();
                } else {
                    let res = this.formdata.metaJson.filter((item) => {
                        return item.id === id;
                    });
                    res[0].shzt = zt;
                    this.getFromData();
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.form_basic {
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    height: 960px;
    .map {
        width: 60%;
        position: relative;
        height: 880px;
        float: left;
        .map-tool {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
        }
    }
    .el-form {
        width: 40%;
        float: left;
        .checkBtn {
            width: 100%;
            text-align: right;
        }
        .ysCheck1 {
            width: calc(100% - 50px);
        }
        .ysCheck2 {
            width: 40px;
            margin-left: 10px;
        }
        .tableAtMapBottom {
            position: relative;
            left: -1000px;
            top: -50px;
        }
        .tableAtMapBottomJcy {
            position: relative;
            left: -950px;
            top: -50px;
        }
    }
}
.form_basic_hide {
    height: 0;
    overflow: hidden;
    padding: 0;
}
.form_info {
    i {
        margin-left: 10px;
        &.el-icon-success {
            color: #67c23a;
        }
        &.el-icon-warning {
            color: #e6a23c;
        }
    }
    padding: 10px 0;
    .tab-name {
        font-size: 16px;
    }
    .model-title {
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
    }
    .file-main {
        .file-title {
            padding-left: 20px;
        }
    }
}
.fold {
    height: 20px;
    text-align: center;
    i {
        font-size: 30px;
        color: #a7b498;
        cursor: pointer;
    }
}
.errorPartWrap {
    width: 100%;
    height: 189px;
    border: 1px dashed #eee;
    background: #fff;
    color: #333333;
    font-size: 14px;
    padding: 10px 20px;
    .errorDesc {
        width: 400px;
        .errorItem {
            margin-bottom: 10px;
        }
    }

    .carouselPart {
        .imgPart {
            .imgItem {
                height: 167px;
            }
        }
    }
}
</style>
