<!-- map_draw 地图标绘组件-->
<template>
    <div id="mapId"></div>
</template>

<script>
// import "leaflet/dist/leaflet.css"
// import "leaflet/dist/leaflet.js"

import "@libs/leaflet-draw/Leaflet.draw.js";
import "@libs/leaflet-draw/Leaflet.Draw.Event.js";
import "@libs/leaflet-draw/leaflet.draw.css";
import "@libs/leaflet-draw/Toolbar.js";
import "@libs/leaflet-draw/Tooltip.js";
import "@libs/leaflet-draw/ext/GeometryUtil.js";
import "@libs/leaflet-draw/ext/LatLngUtil.js";
import "@libs/leaflet-draw/ext/LineUtil.Intersect.js";
import "@libs/leaflet-draw/ext/Polygon.Intersect.js";
import "@libs/leaflet-draw/ext/Polyline.Intersect.js";
import "@libs/leaflet-draw/ext/TouchEvents.js";

import "@libs/leaflet-draw/draw/DrawToolbar.js";
import "@libs/leaflet-draw/draw/handler/Draw.Feature.js";
import "@libs/leaflet-draw/draw/handler/Draw.SimpleShape.js";
import "@libs/leaflet-draw/draw/handler/Draw.Polyline.js";
import "@libs/leaflet-draw/draw/handler/Draw.Marker.js";
import "@libs/leaflet-draw/draw/handler/Draw.Circle.js";
import "@libs/leaflet-draw/draw/handler/Draw.CircleMarker.js";
import "@libs/leaflet-draw/draw/handler/Draw.Polygon.js";
import "@libs/leaflet-draw/draw/handler/Draw.Rectangle.js";

import "@libs/leaflet-draw/edit/EditToolbar.js";
import "@libs/leaflet-draw/edit/handler/EditToolbar.Edit.js";
import "@libs/leaflet-draw/edit/handler/EditToolbar.Delete.js";
import "@libs/leaflet-draw/Control.Draw.js";

import "@libs/leaflet-draw/edit/handler/Edit.Poly.js";
import "@libs/leaflet-draw/edit/handler/Edit.SimpleShape.js";
import "@libs/leaflet-draw/edit/handler/Edit.Rectangle.js";
import "@libs/leaflet-draw/edit/handler/Edit.Marker.js";
import "@libs/leaflet-draw/edit/handler/Edit.CircleMarker.js";
import "@libs/leaflet-draw/edit/handler/Edit.Circle.js";
export default {
    name: "MapDraw",
    components: {},
    props: {
        basicMap: Object,
        drawConf: {
            type: Object,
            default() {
                return {
                    draw: {
                        polygon: true,
                        marker: false,
                        circle: false,
                        polyline: true,
                        rectangle: false,
                        writing: false,
                        circlemarker: false,
                    },
                    position: "",
                    show: true,
                };
            },
        },
        jsonStr: String,
    },
    data() {
        return {
            jsonData: [],
            layerData: [],
            drawnItems: null,
        };
    },
    computed: {},
    watch: {
        basicMap: {
            immediate: true,
            handler() {
                if (this.basicMap) {
                    this.initDrawControl();
                }
            },
        },
        // drawConf: {
        //     immediate: true,
        //     handler() {
        //         if (this.drawConf && this.drawConf.show) {
        //             // this.initDrawControl();
        //         }
        //     },
        // },
        jsonStr: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.getJsonData();
                });
            },
        },
    },
    mounted() {},
    destroyed() {
        if (this.drawControl) {
            this.drawnItems.clearLayers();
            this.drawControl.remove();
        }
    },
    methods: {
        // 初始化绘制工具
        initDrawControl() {
            // console.log("enter 初始化绘制工具");
            let mymap = this.basicMap;
            let drawConf = this.initDrawConf();
            if (this.drawConf.show) {
                this.drawControl = new window.L.Control.Draw(drawConf);
                mymap.addControl(this.drawControl);
            }
            let _this = this;
            this.drawnItems = drawConf.edit.featureGroup;
            this.basicMap.on("draw:created", (event) => {
                let layer = event.layer;
                _this.drawnItems.addLayer(layer);
                this.getLayerJson(event);
                this.$emit("updateDraw", "created", event);
            });
            this.basicMap.on("draw:deletestart", (event) => {
                this.$emit("updateDraw", "deletestart", event);
            });
            this.basicMap.on("draw:deleted", (event) => {
                let layer = event.layers._layers;
                this.drawnItems.removeLayer(layer._leaflet_id);
                this.getLayerJson(event);
                this.$emit("updateDraw", "deleted", event);
            });
            this.basicMap.on("draw:edited", (event) => {
                this.$emit("updateDraw", "edited", event);
            });
            this.basicMap.on("draw:editstop", (event) => {
                this.getLayerJson(event);
            });
        },
        // 初始化绘制配置
        initDrawConf() {
            let option1 = {
                position: this.drawConf.position || "topright",
                edit: {
                    featureGroup: window.L.featureGroup().addTo(this.basicMap),
                    poly: {
                        allowIntersection: false,
                    },
                    remove: true,
                },
                draw: {
                    polygon: true,
                    marker: {
                        icon: window.L.icon({
                            iconUrl: require("@image/bigScreen/icon/location_blue.png"),
                            iconAnchor: [8.5, 12.5],
                        }),
                    },
                    circle: false,
                    polyline: true,
                    rectangle: false,
                    writing: false,
                    circlemarker: false,
                },
            };
            let option2 = this.drawConf;
            $.extend(option1.draw, option2.draw);
            $.extend(option1.edit, option2.edit);
            return option1;
        },
        // 获得标绘json数组
        getJsonData() {
            if (this.drawnItems) {
                this.drawnItems.clearLayers;
                this.drawnItems.eachLayer(function(layer) {
                    if (
                        !layer._container ||
                        ("" + jQuery(layer._container).attr("class")).replace(
                            /\s/g,
                            ""
                        ) != "leaflet-layer"
                    ) {
                        layer.remove();
                    }
                });
            }
            this.jsonData = this.jsonStr ? $.parseJSON(this.jsonStr) : [];
            console.log("获得标绘json数组", this.jsonData);
            this.jsonData.forEach((geo) => {
                // eslint-disable-next-line
                L.geoJSON(geo, {
                    style: function(feature) {
                        return {
                            color: "yellow",
                        };
                    },
                    onEachFeature: (feature, layer) => {
                        layer.on("click", () => {
                            this.$emit("clickLayer", layer);
                        });
                        if (layer.feature.geometry.type === "Point") {
                            // eslint-disable-next-line
                            let iconS = L.icon({
                                iconUrl: require("@image/bigScreen/icon/location_blue.png"),
                                iconAnchor: [8.5, 12.5],
                            });
                            layer.setIcon(iconS);
                        }
                        this.drawnItems.addLayer(layer);
                    },
                });
            });
        },
        // 将图层转Json
        getLayerJson(type) {
            let JsonList = [];
            this.drawnItems.eachLayer((e) => {
                let json = e.toGeoJSON();
                JsonList.push(json);
            });
            let JsonStr = JSON.stringify(JsonList);
            this.$emit("update:jsonStr", JsonStr, type);
        },
        // 删除图层
        deleteLayer(layer) {
            this.drawnItems.removeLayer(layer);
        },
    },
};
</script>
<style scoped></style>
